<template>
  <div class="templates">
    <MainHeader v-if="templatesList.vacancies" class="templates__header" title="Шаблоны"
      :number="templatesList.vacancies.length ? templatesList.vacancies_count : ''" btnText="Новый шаблон"
      @btnClick="$router.push('/templates/new')" />

    <div class="templates__content" v-if="!errorGet && templatesList.vacancies">
      <div class="templates__cards-wrapper" v-if="templatesList.vacancies.length">
        <div class="templates__cards">
          <TemplateCard class="templates__card" v-for="vacancy in templatesList.vacancies" :key="vacancy.id"
            :vacancy="vacancy" @createVacancy="onCreateVacancy" />
        </div>
      </div>

      <div class="templates__no-content" v-if="!templatesList.vacancies.length">
        <VNoContent title="Список шаблонов пуст" description="Вы еще не добавили шаблоны" />
      </div>
    </div>
  </div>
  <AccessClosed v-if="templatesList.blocked_by_contract" />
</template>

<script setup>
import { onMounted, onActivated, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import vacancies from "@/api/vacancies";
import MainHeader from "@/components/MainHeader";
import TemplateCard from "@/components/TemplateCard";
import AccessClosed from "@/components/AccessClosed"

const router = useRouter()
const route = useRoute()
const store = useStore();
const errorGet = ref(false)
const templatesList = ref({})
const vacancyId = ref(route.params.id);
const vacancy = ref({});

const getTemplates = async () => {
  try {
    templatesList.value = await vacancies.getVacancies({
      is_template: 1
    })
  } catch (error) {
    errorGet.value = true
    console.log(error)
  }
}

const getVacancy = async () => {
  try {
    vacancy.value = await vacancies.getVacancy(vacancyId.value);
  } catch (error) {
    console.log(error);
  }
};

const createVacancy = async () => {
  try {
    await vacancies.createVacancies(vacancyId.value);
    await router.push("/vacancies");
  } catch (error) {
    showPopupErrorModal.value = true;
    console.log(error);
  }
};

const getCreateVacancies = async () => {
  try {
    const response = await vacancies.getCreateVacancies();
    vacancyId.value = response.id;
    return response
  } catch (error) {
    console.log(error);
  }

};


const onCreateVacancy = async (templateId) => {
  const createdVacancy = await getCreateVacancies()
  const vacancyId = createdVacancy.id
  localStorage.setItem('templateId', templateId)
  router.push({
    path: `/vacancies/${vacancyId}/edit`,
  })
}

onActivated(() => {
  if (route.query.update) {
    router.push('/templates')
    getTemplates()
  }
})

onMounted(() => getTemplates())
</script>

<style scoped lang="scss">
.templates {
  padding-bottom: 30px;

  &__header {
    margin-top: 32px;
  }

  &__content {
    margin-top: 24px;
  }

  &__cards-wrapper {
    min-height: calc(100vh - 255px);
  }

  &__cards {
    margin-left: -32px;
    margin-top: -24px;
    display: flex;
    flex-wrap: wrap;
  }

  &__card {
    width: calc(33.3% - 32px);
    margin-top: 24px;
    margin-left: 32px;
  }

  &__no-content {
    width: 100%;
    height: calc(100vh - 143px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
